import './App.css';
// import { Modal } from './modal';
import { Top } from './top';

function App() {
  return (
    <div className="App">
      <Top />
      {/* <Modal /> */}
    </div>
  );
};

export default App;